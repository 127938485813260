import styled from 'styled-components'

const LoadingStyle = styled.div`
  img {
    display: block;
    margin: 2rem auto;
  }

  .spinner {
    font-size: 3rem;
    display: block;
    margin: 2rem auto;
    animation: spin infinite 1.25s linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default LoadingStyle