import React from "react";
import LoadingStyle from './loading-style';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// @ts-ignore
import * as logo from '../../images/jm_logo.svg';

const Loading = () => (
  <LoadingStyle
    style={{
      alignItems: "center",
      backgroundColor: "#051232",
      color: "#ffffff",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 10000,
    }}
  >
    <div>
      <img 
        src={logo} 
        alt="Joystck Maverick" 
        width="150" 
        height="150"
      />
      <AiOutlineLoading3Quarters className="spinner" />
    </div>
  </LoadingStyle>
)

export default Loading;